/* eslint-disable react/no-array-index-key */
import React, { lazy, Suspense, Fragment } from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import DashboardLayout from 'src/layouts/DashboardLayout';
import MainLayout from 'src/layouts/MainLayout';
import HomeView from 'src/views/pages/HomeView';
import LoadingScreen from 'src/components/LoadingScreen';
import AuthGuard from 'src/components/Guard/AuthGuard';
import GuestGuard from 'src/components/Guard/GuestGuard';
import AppointmentDashboard from './views/pages/Appointment';
import AboutUsDashboard from './views/pages/AboutUs';
import ContactUsDashboard from './views/pages/ContactUs';
import AdminHealthGuard from './components/Guard/AdminHealthGuard';
import DoctorHealthGuard from './components/Guard/DoctorHealthGuard';
import SecretaryHealthGuard from './components/Guard/SecretaryHealthGuard';
import PatientHealthGuard from './components/Guard/PatientHealthGuard';
import PharmacyAdminHealthGuard from './components/Guard/PharmacyAdminHealthGuard';
import PharmacyAssistantHealthGuard from './components/Guard/PharmacyAssistantHealthGuard';
import ImagingHealthGuard from './components/Guard/ImagingHealthGuard';
import LaboratoryHealthGuard from './components/Guard/LaboratoryHealthGuard';

const routesConfig = [
  {
    exact: true,
    path: '/',
    component: () => <Redirect to="/home" />
  },
  {
    exact: true,
    path: '/404',
    component: lazy(() => import('src/views/pages/Error404View'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/login',
    component: lazy(() => import('src/views/auth/LoginView'))
  },
  {
    exact: true,
    path: '/login-unprotected',
    component: lazy(() => import('src/views/auth/LoginView'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/register',
    component: lazy(() => import('src/views/auth/RegisterView'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/recover/password',
    component: lazy(() => import('src/views/auth/RecoverPasswordView'))
  },

  {
    exact: true,
    guard: GuestGuard,
    path: '/recover/password/reset-form/:email/:token',
    component: lazy(() =>
      import('src/views/auth/RecoverPasswordView/NewPasswordView/')
    )
  },
  {
    exact: true,
    path: '/register-unprotected',
    component: lazy(() => import('src/views/auth/RegisterView'))
  },
  {
    path: '/app',
    guard: AuthGuard,
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: '/app',
        component: () => <Redirect to="/app/reports/dashboard" />
      },
      {
        exact: true,
        path: '/app/account',
        component: lazy(() => import('src/views/pages/AccountView'))
      },
      {
        exact: true,
        path: '/app/reports/dashboard',
        component: lazy(() => import('src/views/reports/DashboardView'))
      },
      {
        exact: true,
        path: '/app/management/companies',
        component: lazy(() => import('src/views/management/Companies'))
      },
      {
        exact: true,
        path: '/app/management/companies/create',
        component: lazy(() => import('src/views/management/Companies/Create'))
      },
      {
        exact: true,
        path: '/app/management/companies/payment/history',
        component: lazy(() => import('src/views/reports/DashboardView/history'))
      },
      {
        exact: true,
        path: '/app/management/companies/account/soa',
        component: lazy(() => import('src/views/reports/DashboardView/soa'))
      },
      {
        exact: true,
        path: '/app/management/companies/sentattachment/:type/:brgycode',
        component: lazy(() =>
          import('src/views/management/Companies/RequestRegistration')
        )
      },
      {
        exact: true,
        path: '/app/management/companies/edit/:company_code',
        component: lazy(() => import('src/views/management/Companies/Edit'))
      },
      // {
      //   exact: true,
      //   path: '/app/management/companies/assessment/details/currentdue/payment/notifications',
      //   component: lazy(() => import('src/views/management/Companies/Assessment/Details/SampleNotification'))
      // },

      {
        exact: true,
        path: '/app/management/companies/holdings/location/:type/:company_code',
        component: lazy(() => import('src/views/pages/location'))
      },

      {
        exact: true,
        path: '/app/management/companies/assessment/:company_code',
        component: lazy(() =>
          import('src/views/management/Companies/Assessment')
        )
      },
      {
        exact: true,
        path:
          '/app/management/companies/assessment/request/requirements/sent/:assessment_id/:brgycode/:company_name',
        component: lazy(() =>
          import('src/views/management/Companies/Assessment/Requirements')
        )
      },
      {
        exact: true,
        path: '/app/management/companies/assessment/request/:company_code',
        component: lazy(() =>
          import('src/views/management/Companies/Assessment/Request')
        )
      },
      {
        exact: true,
        path:
          '/app/management/companies/assessment/details/:assessment_code/:company_code',
        component: lazy(() =>
          import('src/views/management/Companies/Assessment/Details')
        )
      },
      {
        exact: true,
        path:
          '/app/management/companies/assessment/details/:assessment_code/:company_code/',
        component: lazy(() =>
          import('src/views/management/Companies/Assessment/Details')
        )
      },
      {
        exact: true,
        path:
          '/app/management/companies/assessment/details/paymentsuccessful/:assessment_code/:company_code',
        component: lazy(() =>
          import(
            'src/views/management/Companies/Assessment/Details/PaynamicPaymentSuccessful'
          )
        )
      },
      {
        exact: true,
        path:
          '/app/management/companies/assessment/details/currentdue/payments/gcash/redirect/failed',
        component: lazy(() =>
          import(
            'src/views/management/Companies/Assessment/Details/PaymentDialogGcashRedirectFailed'
          )
        )
      },
      {
        exact: true,
        path:
          '/app/management/companies/assessment/edit/:assessment_code/:company_code',
        component: lazy(() =>
          import('src/views/management/Companies/Assessment/Edit')
        )
      },
      {
        exact: true,
        path: '/app/calendar',
        component: lazy(() => import('src/views/calendar/CalendarView'))
      },
      {
        exact: true,
        path: '/app/kanban',
        component: lazy(() => import('src/views/kanban/KanbanView'))
      },
      {
        exact: true,
        path: ['/app/chat/new', '/app/chat/:threadKey'],
        component: lazy(() => import('src/views/chat/ChatView'))
      },
      {
        exact: true,
        path: '/app/chat',
        component: () => <Redirect to="/app/chat/new" />
      },
      {
        exact: true,
        path: [
          '/app/mail/label/:customLabel/:mailId?',
          '/app/mail/:systemLabel/:mailId?'
        ],
        component: lazy(() => import('src/views/mail/MailView'))
      },
      {
        exact: true,
        path: '/app/mail',
        component: () => <Redirect to="/app/mail/all" />
      },
      {
        exact: true,
        path: '/app/projects/overview',
        component: lazy(() => import('src/views/projects/OverviewView'))
      },
      {
        exact: true,
        path: '/app/projects/browse',
        component: lazy(() => import('src/views/projects/ProjectBrowseView'))
      },
      {
        exact: true,
        path: '/app/projects/create',
        component: lazy(() => import('src/views/projects/ProjectCreateView'))
      },
      {
        exact: true,
        path: '/app/projects/:id',
        component: lazy(() => import('src/views/projects/ProjectDetailsView'))
      },
      {
        exact: true,
        path: '/app/projects',
        component: () => <Redirect to="/app/projects/browse" />
      },
      {
        exact: true,
        path: '/app/social/feed',
        component: lazy(() => import('src/views/social/FeedView'))
      },
      {
        exact: true,
        path: '/app/social/profile',
        component: lazy(() => import('src/views/social/ProfileView'))
      },
      {
        exact: true,
        path: '/app/social',
        component: () => <Redirect to="/app/social/profile" />
      },
      {
        exact: true,
        path: '/app/extra/charts/apex',
        component: lazy(() => import('src/views/extra/charts/ApexChartsView'))
      },
      {
        exact: true,
        path: '/app/extra/forms/formik',
        component: lazy(() => import('src/views/extra/forms/FormikView'))
      },
      {
        exact: true,
        path: '/app/extra/forms/redux',
        component: lazy(() => import('src/views/extra/forms/ReduxFormView'))
      },
      {
        exact: true,
        path: '/app/extra/editors/draft-js',
        component: lazy(() => import('src/views/extra/editors/DraftEditorView'))
      },
      {
        exact: true,
        path: '/app/extra/editors/quill',
        component: lazy(() => import('src/views/extra/editors/QuillEditorView'))
      },
      {
        component: () => <Redirect to="/404" />
      }
    ]
  },
  //from
  {
    path: '/admin',
    guard: AdminHealthGuard,
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: '/admin/account',
        component: lazy(() => import('src/views/pages/AccountView'))
      },
      //otis
      {
        exact: true,
        path: '/admin',
        component: () => <Redirect to="/admin/dashboard" />
      },
      {
        exact: true,
        path: '/admin/dashboard',
        component: lazy(() => import('src/views/otis/OTISHomeDashboard'))
      },
      {
        exact: true,
        path: '/admin/collection-type',
        component: lazy(() =>
          import('src/views/otis/collection-type/OTISCollectionType')
        )
      },
      //transaction
      //collection-issuance
      {
        exact: true,
        path: '/admin/transaction/collection/issuance-official-receipt',
        component: lazy(() =>
          import(
            'src/views/otis/transaction/collection/issuance-or/OTISIssuanceOfficialReceipt'
          )
        )
      },
      //collection-ctc-corp
      {
        exact: true,
        path: '/admin/transaction/collection/ctc-corp',
        component: lazy(() =>
          import('src/views/otis/transaction/collection/ctc-corp/OTISCTCCorp')
        )
      },
      //collection-ctc-ind
      {
        exact: true,
        path: '/admin/transaction/collection/ctc-ind',
        component: lazy(() =>
          import('src/views/otis/transaction/collection/ctc-ind/OTISCTCInd')
        )
      },
      //collection-burial-permit
      {
        exact: true,
        path: '/admin/transaction/collection/burial-permit-fee-receipt',
        component: lazy(() =>
          import(
            'src/views/otis/transaction/collection/burial/OTISBurialPermit'
          )
        )
      },
      //collection-business-permit
      {
        exact: true,
        path: '/admin/transaction/collection/business-permit',
        component: lazy(() =>
          import(
            'src/views/otis/transaction/collection/business-permit/OTISBusinessPermit'
          )
        )
      },
      //disbursement-disbursement-voucher
      {
        exact: true,
        path: '/admin/transaction/disbursement/disbursement-voucher',
        component: lazy(() =>
          import(
            'src/views/otis/transaction/disbursement/disbursement-voucher/OTISDisbursementVoucher'
          )
        )
      },

      //referential
      //fund
      {
        exact: true,
        path: '/admin/referential/fund/fund-group',
        component: lazy(() =>
          import('src/views/otis/referential/funds/funds-group/OTISFundsGroup')
        )
      },
      {
        exact: true,
        path: '/admin/referential/fund/fund-code',
        component: lazy(() =>
          import('src/views/otis/referential/funds/funds-code/OTISFundsCode')
        )
      },
      //allotment
      {
        exact: true,
        path: '/admin/referential/allotment/allotment-code',
        component: lazy(() =>
          import(
            'src/views/otis/referential/allotment/allotment-code/OTISAllotmentCode'
          )
        )
      },
      {
        exact: true,
        path: '/admin/referential/allotment/allotment-class',
        component: lazy(() =>
          import(
            'src/views/otis/referential/allotment/allotment-class/OTISAllotmentClass'
          )
        )
      },
      //accountability
      //accountable forms
      {
        exact: true,
        path: '/admin/referential/accountability/accountable-forms',
        component: lazy(() =>
          import(
            'src/views/otis/referential/accountability/accountable-forms/OTISAccountableForms'
          )
        )
      },
      {
        exact: true,
        path: '/admin/referential/accountability/cost-center',
        component: lazy(() =>
          import(
            'src/views/otis/referential/accountability/cost-center/OTISCostCenter'
          )
        )
      },
      {
        exact: true,
        path: '/admin/referential/accountability/officer-type',
        component: lazy(() =>
          import(
            'src/views/otis/referential/accountability/office-type/OTISOfficeType'
          )
        )
      },
      {
        exact: true,
        path: '/admin/referential/accountability/liq-disb-collector',
        component: lazy(() =>
          import(
            'src/views/otis/referential/accountability/liq-disb-collector/OTISLiqDisbCollector'
          )
        )
      },
      {
        exact: true,
        path: '/admin/referential/accountability/form-code',
        component: lazy(() =>
          import(
            'src/views/otis/referential/accountability/form-code/OTISFormCode'
          )
        )
      },
      {
        exact: true,
        path: '/admin/referential/accountability/or-template',
        component: lazy(() =>
          import(
            'src/views/otis/referential/accountability/or-template/OTISORTemplate'
          )
        )
      },
      {
        exact: true,
        path: '/admin/referential/accountability/bank-depository',
        component: lazy(() =>
          import(
            'src/views/otis/referential/accountability/bank-depository/OTISBankDepository'
          )
        )
      },
      {
        exact: true,
        path: '/admin/referential/accountability/check-booklets',
        component: lazy(() =>
          import(
            'src/views/otis/referential/accountability/check-booklets/OTISCheckBooklets'
          )
        )
      },
      {
        exact: true,
        path: '/admin/referential/accountability/creditor-payee',
        component: lazy(() =>
          import(
            'src/views/otis/referential/accountability/creditor-payee/OTISCreditorPayee'
          )
        )
      },
      //chart of accounts
      {
        exact: true,
        path: '/admin/referential/chart-accounts/object-code',
        component: lazy(() =>
          import(
            'src/views/otis/referential/accounts-chart/object-code/OTISObjectCode'
          )
        )
      },
      {
        exact: true,
        path: '/admin/referential/chart-accounts/account-code',
        component: lazy(() =>
          import(
            'src/views/otis/referential/accounts-chart/account-code/OTISAccountCode'
          )
        )
      },
      //fpp
      {
        exact: true,
        path: '/admin/referential/fpp/fpp-code',
        component: lazy(() =>
          import('src/views/otis/referential/fpp/fpp-code/OTISFPPCode')
        )
      },
      {
        exact: true,
        path: '/admin/referential/fpp/function-code',
        component: lazy(() =>
          import(
            'src/views/otis/referential/fpp/function-code/OTISFunctionCode'
          )
        )
      },
      {
        exact: true,
        path: '/admin/referential/fpp/program-code',
        component: lazy(() =>
          import('src/views/otis/referential/fpp/program-code/OTISProgramCode')
        )
      },
      //reports
      //collection
      {
        exact: true,
        path: '/admin/reports/collection/abstract-collection',
        component: lazy(() =>
          import('src/views/otis/reports/collection/OTISAbstractCollection')
        )
      },
      {
        exact: true,
        path:
          '/admin/reports/accountable-forms/report-accountability-accountable-forms',
        component: lazy(() =>
          import(
            'src/views/otis/reports/accountable-forms/OTISReportOfAccountableForms'
          )
        )
      },
      {
        exact: true,
        path:
          '/admin/reports/accountable-forms/consolidated-report-accountability-accountable-forms',
        component: lazy(() =>
          import(
            'src/views/otis/reports/accountable-forms/OTISCRAforAccountableForms'
          )
        )
      },
      {
        exact: true,
        path: '/admin/reports/rcd',
        component: lazy(() =>
          import('src/views/otis/reports/ReportofCollectionAndDepositsRCD')
        )
      },
      {
        exact: true,
        path: '/admin/reports/rcd/:rcd_no',
        component: lazy(() =>
          import('src/views/otis/reports/RCDNoDetailsPrint')
        )
      },
      {
        exact: true,
        path: '/admin/reports/rclo',
        component: lazy(() =>
          import('src/views/otis/reports/ReportOfCollectionByLiqOfficer')
        )
      },
      {
        exact: true,
        path: '/admin/reports/rclo/:rclo_no',
        component: lazy(() =>
          import('src/views/otis/reports/RCLONoDetailsPrint')
        )
      },
      //process
      //rcd processing
      {
        exact: true,
        path: '/admin/process/rcd-processing',
        component: lazy(() =>
          import('src/views/otis/process/rcd-processing/OTISRCDProcessing')
        )
      },
      //rclo processing
      {
        exact: true,
        path: '/admin/process/rclo-processing',
        component: lazy(() =>
          import('src/views/otis/process/rclo-processing/OTISRCLOProcessing')
        )
      },
      //admin userlist
      {
        exact: true,
        path: '/admin/users',
        component: lazy(() => import('src/views/otis/admin/users/OTISUserList'))
      },
      {
        component: () => <Redirect to="/404" />
      }
    ]
  },
  {
    path: '/doctor',
    guard: DoctorHealthGuard,
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: '/doctor/account',
        component: lazy(() => import('src/views/pages/AccountView'))
      },
      //health2
      {
        exact: true,
        path: '/doctor',
        component: () => <Redirect to="/doctor/appointment" />
      },
      {
        exact: true,
        path: '/doctor/appointment',
        component: lazy(() => import('src/views/health2/doctor/appointment'))
      },
      {
        exact: true,
        path: '/doctor/service',
        component: lazy(() => import('src/views/health2/doctor/services'))
      },
      {
        exact: true,
        path: '/doctor/patient',
        component: lazy(() => import('src/views/health2/doctor/patient'))
      },

      // jhomar changes
      {
        exact: true,
        path: '/doctor/patient/:patient_id',
        component: lazy(() =>
          import('src/views/health2/doctor/patient/details')
        )
      },

      {
        exact: true,
        path: '/doctor/report',
        component: lazy(() => import('src/views/health2/doctor/report'))
      },
      {
        component: () => <Redirect to="/404" />
      }
    ]
  },
  {
    path: '/secretary',
    guard: SecretaryHealthGuard,
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: '/secretary/account',
        component: lazy(() => import('src/views/pages/AccountView'))
      },
      //health2
      {
        exact: true,
        path: '/secretary',
        component: () => <Redirect to="/secretary/appointment" />
      },
      {
        exact: true,
        path: '/secretary/appointment',
        component: lazy(() => import('src/views/health2/secretary/appointment'))
      },
      {
        exact: true,
        path: '/secretary/patient',
        component: lazy(() => import('src/views/health2/secretary/patient'))
      },
      {
        exact: true,
        path: '/secretary/billing',
        component: lazy(() => import('src/views/health2/secretary/billing'))
      },
      {
        exact: true,
        path: '/secretary/report',
        component: lazy(() => import('src/views/health2/secretary/report'))
      },
      {
        exact: true,
        path: '/secretary/follow-up',
        component: lazy(() => import('src/views/health2/secretary/follow-up'))
      },
      {
        component: () => <Redirect to="/404" />
      }
    ]
  },
  {
    path: '/patient',
    guard: PatientHealthGuard,
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: '/patient/account',
        component: lazy(() => import('src/views/pages/AccountView'))
      },
      //health2
      {
        exact: true,
        path: '/patient',
        component: () => <Redirect to="/patient/profile" />
      },
      {
        exact: true,
        path: '/patient/profile',
        component: lazy(() => import('src/views/health2/patient/profile'))
      },
      {
        exact: true,
        path: '/patient/vitals',
        component: lazy(() =>
          import('src/views/health2/patient/profile/vitals')
        )
      },
      {
        exact: true,
        path: '/patient/allergies',
        component: lazy(() =>
          import('src/views/health2/patient/profile/allergies')
        )
      },
      {
        exact: true,
        path: '/patient/diagnosis',
        component: lazy(() =>
          import('src/views/health2/patient/profile/diagnosis')
        )
      },
      {
        exact: true,
        path: '/patient/medication',
        component: lazy(() =>
          import('src/views/health2/patient/profile/medication')
        )
      },
      {
        exact: true,
        path: '/patient/diet',
        component: lazy(() => import('src/views/health2/patient/profile/diet'))
      },
      {
        exact: true,
        path: '/patient/family-history',
        component: lazy(() =>
          import('src/views/health2/patient/profile/familyhistory')
        )
      },
      {
        exact: true,
        path: '/patient/laboratory',
        component: lazy(() =>
          import('src/views/health2/patient/profile/laboratory')
        )
      },
      {
        exact: true,
        path: '/patient/imaging',
        component: lazy(() =>
          import('src/views/health2/patient/profile/imaging')
        )
      },
      // {
      //   exact: true,
      //   path: '/patient/history',
      //   component: lazy(() =>
      //     import('src/views/health2/patient/profile/history')
      //   )
      // },
      {
        exact: true,
        path: '/patient/health-graph',
        component: lazy(() => import('src/views/health2/patient/healthgraph'))
      },
      {
        exact: true,
        path: '/patient/health-graph/pain',
        component: lazy(() =>
          import('src/views/health2/patient/healthgraph/pain')
        )
      },
      {
        exact: true,
        path: '/patient/health-graph/temperature',
        component: lazy(() =>
          import('src/views/health2/patient/healthgraph/temperature')
        )
      },
      {
        exact: true,
        path: '/patient/health-graph/blood-pressure',
        component: lazy(() =>
          import('src/views/health2/patient/healthgraph/blood-pressure')
        )
      },
      {
        exact: true,
        path: '/patient/health-graph/pulse',
        component: lazy(() =>
          import('src/views/health2/patient/healthgraph/pulse')
        )
      },
      {
        exact: true,
        path: '/patient/health-graph/respiratory',
        component: lazy(() =>
          import('src/views/health2/patient/healthgraph/respiratory')
        )
      },
      {
        exact: true,
        path: '/patient/health-graph/weight',
        component: lazy(() =>
          import('src/views/health2/patient/healthgraph/weight')
        )
      },
      {
        exact: true,
        path: '/patient/health-graph/glucose',
        component: lazy(() =>
          import('src/views/health2/patient/healthgraph/glucose')
        )
      },
      {
        exact: true,
        path: '/patient/health-graph/cholesterol',
        component: lazy(() =>
          import('src/views/health2/patient/healthgraph/cholesterol')
        )
      },
      {
        exact: true,
        path: '/patient/health-graph/uric-acid',
        component: lazy(() =>
          import('src/views/health2/patient/healthgraph/uric-acid')
        )
      },
      {
        exact: true,
        path: '/patient/health-graph/calcium',
        component: lazy(() =>
          import('src/views/health2/patient/healthgraph/calcium')
        )
      },
      {
        exact: true,
        path: '/patient/health-graph/chloride',
        component: lazy(() =>
          import('src/views/health2/patient/healthgraph/chloride')
        )
      },
      {
        exact: true,
        path: '/patient/health-graph/creatinine',
        component: lazy(() =>
          import('src/views/health2/patient/healthgraph/creatinine')
        )
      },
      {
        exact: true,
        path: '/patient/health-graph/hdl',
        component: lazy(() =>
          import('src/views/health2/patient/healthgraph/hdl')
        )
      },
      {
        exact: true,
        path: '/patient/health-graph/ldl',
        component: lazy(() =>
          import('src/views/health2/patient/healthgraph/ldl')
        )
      },
      {
        exact: true,
        path: '/patient/health-graph/lithium',
        component: lazy(() =>
          import('src/views/health2/patient/healthgraph/lithium')
        )
      },
      {
        exact: true,
        path: '/patient/health-graph/magnesium',
        component: lazy(() =>
          import('src/views/health2/patient/healthgraph/magnesium')
        )
      },
      {
        exact: true,
        path: '/patient/health-graph/potassium',
        component: lazy(() =>
          import('src/views/health2/patient/healthgraph/potassium')
        )
      },
      {
        exact: true,
        path: '/patient/health-graph/protein',
        component: lazy(() =>
          import('src/views/health2/patient/healthgraph/protein')
        )
      },
      {
        exact: true,
        path: '/patient/health-graph/sodium',
        component: lazy(() =>
          import('src/views/health2/patient/healthgraph/sodium')
        )
      },
      {
        exact: true,
        path: '/patient/clinic',
        component: lazy(() => import('src/views/health2/patient/clinic'))
      },
      {
        exact: true,
        path: '/patient/permission',
        component: lazy(() => import('src/views/health2/patient/permission'))
      },
      {
        component: () => <Redirect to="/404" />
      }
    ]
  },
  {
    path: '/pharmacy-admin',
    guard: PharmacyAdminHealthGuard,
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: '/pharmacy-admin/account',
        component: lazy(() => import('src/views/pages/AccountView'))
      },
      //health2
      {
        exact: true,
        path: '/pharmacy-admin',
        component: () => <Redirect to="/pharmacy-admin/products" />
      },
      {
        exact: true,
        path: '/pharmacy-admin/products',
        component: lazy(() =>
          import('src/views/health2/pharmacyadmin/products')
        )
      },
      {
        exact: true,
        path: '/pharmacy-admin/configs',
        component: lazy(() => import('src/views/health2/pharmacyadmin/config'))
      },
      {
        exact: true,
        path: '/pharmacy-admin/delivery',
        component: lazy(() =>
          import('src/views/health2/pharmacyadmin/delivery')
        )
      },
      {
        exact: true,
        path: '/pharmacy-admin/inventory-report',
        component: lazy(() =>
          import('src/views/health2/pharmacyadmin/inventory-report')
        )
      },
      {
        exact: true,
        path: '/pharmacy-admin/report',
        component: lazy(() => import('src/views/health2/pharmacyadmin/report'))
      },
      {
        exact: true,
        path: '/pharmacy-admin/sales-discount',
        component: lazy(() =>
          import('src/views/health2/pharmacyadmin/sales-discount')
        )
      },
      {
        exact: true,
        path: '/pharmacy-admin/cogs',
        component: lazy(() => import('src/views/health2/pharmacyadmin/cogs'))
      },
      {
        exact: true,
        path: '/pharmacy-admin/net-sales',
        component: lazy(() =>
          import('src/views/health2/pharmacyadmin/net-sales')
        )
      },
      {
        exact: true,
        path: '/pharmacy-admin/return-product',
        component: lazy(() => import('src/views/health2/pharmacyadmin/return'))
      },
      {
        exact: true,
        path: '/pharmacy-admin/void-transactions',
        component: lazy(() =>
          import('src/views/health2/pharmacyadmin/void-transaction')
        )
      },
      {
        exact: true,
        path: '/pharmacy-admin/chat-gpt',
        component: lazy(() => import('src/views/health2/pharmacyadmin/chatgpt'))
      },
      {
        component: () => <Redirect to="/404" />
      }
    ]
  },
  {
    path: '/pharmacy-assistant',
    guard: PharmacyAssistantHealthGuard,
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: '/pharmacy-assistant/account',
        component: lazy(() => import('src/views/pages/AccountView'))
      },
      //health2
      {
        exact: true,
        path: '/pharmacy-assistant',
        component: () => <Redirect to="/pharmacy-assistant/products" />
      },
      {
        exact: true,
        path: '/pharmacy-assistant/products',
        component: lazy(() =>
          import('src/views/health2/pharmacyassistant/products')
        )
      },
      {
        exact: true,
        path: '/pharmacy-assistant/over-the-counter',
        component: lazy(() =>
          import('src/views/health2/pharmacyassistant/ordering')
        )
      },
      {
        exact: true,
        path: '/pharmacy-assistant/configs',
        component: lazy(() =>
          import('src/views/health2/pharmacyassistant/configs')
        )
      },
      {
        exact: true,
        path: '/pharmacy-assistant/delivery',
        component: lazy(() =>
          import('src/views/health2/pharmacyassistant/delivery')
        )
      },
      {
        exact: true,
        path: '/pharmacy-assistant/report',
        component: lazy(() =>
          import('src/views/health2/pharmacyassistant/report')
        )
      },
      {
        exact: true,
        path: '/pharmacy-assistant/over-the-counter/receipt/:transaction_id',
        component: lazy(() =>
          import('src/views/health2/pharmacyassistant/print')
        )
      },
      {
        component: () => <Redirect to="/404" />
      }
    ]
  },
  {
    path: '/laboratory',
    guard: LaboratoryHealthGuard,
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: '/laboratory/account',
        component: lazy(() => import('src/views/pages/AccountView'))
      },
      //health2
      {
        exact: true,
        path: '/laboratory',
        component: () => <Redirect to="/laboratory/patients" />
      },
      {
        exact: true,
        path: '/laboratory/patients',
        component: lazy(() => import('src/views/health2/laboratory/patient'))
      },
      {
        exact: true,
        path: '/laboratory/test',
        component: lazy(() => import('src/views/health2/laboratory/test'))
      },
      {
        exact: true,
        path: '/laboratory/report',
        component: lazy(() => import('src/views/health2/laboratory/report'))
      },
      {
        component: () => <Redirect to="/404" />
      }
    ]
  },
  {
    path: '/imaging',
    guard: ImagingHealthGuard,
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: '/imaging/account',
        component: lazy(() => import('src/views/pages/AccountView'))
      },
      //health2
      {
        exact: true,
        path: '/imaging',
        component: () => <Redirect to="/imaging/patients" />
      },
      {
        exact: true,
        path: '/imaging/patients',
        component: lazy(() => import('src/views/health2/imaging/patient'))
      },
      {
        exact: true,
        path: '/imaging/test',
        component: lazy(() => import('src/views/health2/imaging/test'))
      },
      {
        exact: true,
        path: '/imaging/report',
        component: lazy(() => import('src/views/health2/imaging/report'))
      },
      {
        component: () => <Redirect to="/404" />
      }
    ]
  },
  //to
  {
    path: '*',
    layout: MainLayout,
    routes: [
      {
        exact: true,
        path: '/home',
        component: HomeView
      },
      {
        exact: true,
        path: '/appointment',
        component: AppointmentDashboard
      },
      {
        exact: true,
        path: '/about-us',
        component: AboutUsDashboard
      },
      {
        exact: true,
        path: '/contact-us',
        component: ContactUsDashboard
      },
      {
        exact: true,
        path: '/pricing',
        component: lazy(() => import('src/views/pages/PricingView'))
      },
      {
        component: () => <Redirect to="/404" />
      }
    ]
  }
];

const renderRoutes = routes =>
  routes ? (
    <Suspense fallback={<LoadingScreen />}>
      <Switch>
        {routes.map((route, i) => {
          const Guard = route.guard || Fragment;
          const Layout = route.layout || Fragment;
          const Component = route.component;

          return (
            <Route
              key={i}
              path={route.path}
              exact={route.exact}
              render={props => (
                <Guard>
                  <Layout>
                    {route.routes ? (
                      renderRoutes(route.routes)
                    ) : (
                      <Component {...props} />
                    )}
                  </Layout>
                </Guard>
              )}
            />
          );
        })}
      </Switch>
    </Suspense>
  ) : null;

function Routes() {
  return renderRoutes(routesConfig);
}

export default Routes;
