import React, { useRef, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  AppBar,
  Box,
  Hidden,
  IconButton,
  Toolbar,
  makeStyles,
  SvgIcon,
  Typography,
  Button,
  MenuItem,
  Menu,
  Divider,
  ListItemIcon
} from '@material-ui/core';
import { Menu as MenuIcon } from 'react-feather';
// import Logo from 'src/components/Logo';
import { THEMES } from 'src/constants';
import Account from './Account';
import Contacts from './Contacts';
import Notifications from './Notifications';
import Search from './Search';
// import Settings from './Settings';
// import SearchKeyword from './SearchKeyword';
import {
  useDispatch,
  useSelector
  //  useSelector
} from 'react-redux';
import { useHistory } from 'react-router';
import { logout } from 'src/actions/accountActions';
import { useSnackbar } from 'notistack';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

const useStyles = makeStyles(theme => ({
  root: {
    zIndex: theme.zIndex.drawer + 100,
    ...(theme.name === THEMES.LIGHT
      ? {
          boxShadow: 'none',
          backgroundColor: theme.palette.primary.main
        }
      : {}),
    ...(theme.name === THEMES.ONE_DARK
      ? {
          backgroundColor: theme.palette.background.default
        }
      : {})
  },
  toolbar: {
    minHeight: 50
  },
  menuButton: {
    // border: '1px solid red',
    width: 28,
    height: 28
  }
}));

function TopBar({ className, onMobileNavOpen, ...rest }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  //FIRST REF MENU
  const [transactionOpen, setTransactionOpen] = useState(false);
  const transactionRef = useRef(null);
  const [referentialOpen, setReferentialOpen] = useState(false);
  const referentialRef = useRef(null);
  const [processOpen, setProcessOpen] = useState(false);
  const processRef = useRef(null);
  const [reportOpen, setReportOpen] = useState(false);
  const reportRef = useRef(null);
  const [setupOpen, setSetupOpen] = useState(false);
  const setupRef = useRef(null);
  const [adminOpen, setAdminOpen] = useState(false);
  const adminRef = useRef(null);
  const [selected1stID, setSelected1stID] = useState('');

  //SECONDS REF MENU
  const [collectionOpen, setCollectionOpen] = useState(false);
  const collectionRef = useRef(null);
  const [disbursementOpen, setDisbursementOpen] = useState(false);
  const disbursementRef = useRef(null);
  const [fundOpen, setFundOpen] = useState(false);
  const fundRef = useRef(null);
  const [allotmentOpen, setAllotmentOpen] = useState(false);
  const allotmentRef = useRef(null);
  const [fppOpen, setFppOpen] = useState(false);
  const fppRef = useRef(null);
  const [chartOfAccOpen, setChartOfAccOpen] = useState(false);
  const chartOfAccRef = useRef(null);
  const [checklistOpen, setChecklistOpen] = useState(false);
  const checklistRef = useRef(null);
  const [accountabilityOpen, setAccountabilityOpen] = useState(false);
  const accountabilityRef = useRef(null);
  const [utilityOpen, setUtilityOpen] = useState(false);
  const utilityRef = useRef(null);
  const [accountabilityFormsOpen, setAccountabilityFormsOpen] = useState(false);
  const accountabilityFormsRef = useRef(null);
  const [repCollectionOpen, setRepCollectionOpen] = useState(false);
  const repCollectionRef = useRef(null);
  const [listingOpen, setListingOpen] = useState(false);
  const listingRef = useRef(null);
  const [repDisbursementOpen, setRepDisbursementOpen] = useState(false);
  const repDisbursementRef = useRef(null);
  const [systemSecurityOpen, setSystemSecurityOpen] = useState(false);
  const systemSecurityRef = useRef(null);
  const [selected2ndID, setSelected2ndID] = useState('');
  const { user } = useSelector(state => state.account);

  // console.log('user: ', user.roles[0].name);
  // console.log('user: ', user.roles.length > 0);

  const navtab = [
    {
      id: 1,
      width: 150,
      type: 'Transaction',
      open: transactionOpen,
      ref: transactionRef,
      setOpen: setTransactionOpen,
      tabs: [
        {
          id: 1,
          name: 'Collection',
          popup: collectionOpen,
          popupRef: collectionRef,
          setPopup: setCollectionOpen,
          path: null,
          subtabs: [
            {
              id_no: 1,
              name_type: 'Issuance of Official Receipt(OR)',
              path: '/admin/transaction/collection/issuance-official-receipt',
              active: true
            },
            {
              id_no: 2,
              name_type: 'Business Permit(OR)',
              path: '/admin/transaction/collection/business-permit',
              active: true
            },
            {
              id_no: 3,
              name_type: 'Community Tax Certificate(CTC) - Ind',
              path: '/admin/transaction/collection/ctc-ind',
              active: true
            },
            {
              id_no: 4,
              name_type: 'Community Tax Certificate(CTC) - Corp',
              path: '/admin/transaction/collection/ctc-corp',
              active: true
            },
            {
              id_no: 5,
              name_type: 'Burial Permit and Fee Receipt',
              path: '/admin/transaction/collection/burial-permit-fee-receipt',
              active: true
            }
          ]
        },
        {
          id: 2,
          name: 'Disbursement',
          popup: disbursementOpen,
          popupRef: disbursementRef,
          setPopup: setDisbursementOpen,
          path: null,
          subtabs: [
            {
              id_no: 1,
              name_type: 'Disbursement Voucher(DV)',
              path: '/admin/transaction/disbursement/disbursement-voucher',
              active: true
            },
            {
              id_no: 2,
              name_type: 'Report of Disbursement(RD)',
              path: '/admin/transaction/disbursement/report-disbursement',
              active: false
            },
            {
              id_no: 3,
              name_type: 'Report of Checks Issued',
              path: '/admin/transaction/disbursement/report-checks-issued',
              active: false
            },
            {
              id_no: 4,
              name_type: 'Check Register',
              path: '/admin/transaction/disbursement/check-register',
              active: false
            },
            {
              id_no: 5,
              name_type: 'Cash Book',
              path: '/admin/transaction/disbursement/cash-book',
              active: false
            }
          ]
        }
      ]
    },
    {
      id: 2,
      type: 'Referential',
      width: 180,
      open: referentialOpen,
      ref: referentialRef,
      setOpen: setReferentialOpen,
      tabs: [
        {
          id: 1,
          name: 'Fund',
          popup: fundOpen,
          popupRef: fundRef,
          setPopup: setFundOpen,
          path: null,
          subtabs: [
            {
              id_no: 1,
              name_type: 'Fund Group',
              path: '/admin/referential/fund/fund-group',
              active: true
            },
            {
              id_no: 2,
              name_type: 'Fund Code',
              path: '/admin/referential/fund/fund-code',
              active: true
            }
          ]
        },
        {
          id: 2,
          name: 'Allotment',
          popup: allotmentOpen,
          popupRef: allotmentRef,
          setPopup: setAllotmentOpen,
          path: null,
          subtabs: [
            {
              id_no: 1,
              name_type: 'Allotment Code',
              path: '/admin/referential/allotment/allotment-code',
              active: true
            },
            {
              id_no: 2,
              name_type: 'Allotment Class',
              path: '/admin/referential/allotment/allotment-class',
              active: true
            }
          ]
        },
        {
          id: 3,
          name: 'FPP',
          popup: fppOpen,
          popupRef: fppRef,
          setPopup: setFppOpen,
          path: null,
          subtabs: [
            {
              id_no: 1,
              name_type: 'Function Code',
              path: '/admin/referential/fpp/function-code',
              active: true
            },
            {
              id_no: 2,
              name_type: 'Program Code',
              path: '/admin/referential/fpp/program-code',
              active: true
            },
            {
              id_no: 3,
              name_type: 'Project Code',
              path: '/admin/referential/fpp/project-code',
              active: false
            },
            {
              id_no: 4,
              name_type: 'FPP Code',
              path: '/admin/referential/fpp/fpp-code',
              active: true
            }
          ]
        },
        {
          id: 4,
          name: 'Chart Of Accounts',
          popup: chartOfAccOpen,
          popupRef: chartOfAccRef,
          setPopup: setChartOfAccOpen,
          path: null,
          subtabs: [
            {
              id_no: 1,
              name_type: 'Account Code',
              path: '/admin/referential/chart-accounts/account-code',
              active: true
            },
            {
              id_no: 2,
              name_type: 'Group Code',
              path: '/admin/referential/chart-accounts/group-code',
              active: false
            },
            {
              id_no: 3,
              name_type: 'Sub Group Code',
              path: '/admin/referential/chart-accounts/sub-group-code',
              active: false
            },
            {
              id_no: 4,
              name_type: 'Account Type',
              path: '/admin/referential/chart-accounts/account-type',
              active: false
            },
            {
              id_no: 5,
              name_type: 'Sequence Code',
              path: '/admin/referential/chart-accounts/sequence-code',
              active: false
            },
            {
              id_no: 6,
              name_type: 'Sub Sequence Code',
              path: '/admin/referential/chart-accounts/sub-sequence-code',
              active: false
            },
            {
              id_no: 7,
              name_type: 'Expense Code',
              path: '/admin/referential/chart-accounts/expense-code',
              active: false
            },
            {
              id_no: 8,
              name_type: 'Object Code',
              path: '/admin/referential/chart-accounts/object-code',
              active: true
            },
            {
              id_no: 9,
              name_type: 'Sharing',
              path: '/admin/referential/chart-accounts/sharing',
              active: false
            }
          ]
        },
        {
          id: 5,
          name: 'Checklist',
          popup: checklistOpen,
          popupRef: checklistRef,
          setPopup: setChecklistOpen,
          path: null,
          subtabs: [
            {
              id_no: 1,
              name_type: 'Checklist Type',
              path: '/admin/referential/checklist/checklist-type',
              active: false
            },
            {
              id_no: 2,
              name_type: 'Checklist Template',
              path: '/admin/referential/checklist/checklist-template',
              active: false
            },
            {
              id_no: 3,
              name_type: 'Document Type Code',
              path: '/admin/referential/checklist/document-type-code',
              active: false
            }
          ]
        },
        {
          id: 5,
          name: 'Accountability',
          popup: accountabilityOpen,
          popupRef: accountabilityRef,
          setPopup: setAccountabilityOpen,
          path: null,
          subtabs: [
            {
              id_no: 1,
              name_type: 'Accountable Forms',
              path: '/admin/referential/accountability/accountable-forms',
              active: true
            },
            {
              id_no: 2,
              name_type: 'Check Booklets',
              path: '/admin/referential/accountability/check-booklets',
              active: true
            },
            {
              id_no: 3,
              name_type: 'Bank Depository',
              path: '/admin/referential/accountability/bank-depository',
              active: true
            },
            {
              id_no: 4,
              name_type: 'Form Code',
              path: '/admin/referential/accountability/form-code',
              active: true
            },
            {
              id_no: 5,
              name_type: 'Officer Type',
              path: '/admin/referential/accountability/officer-type',
              active: true
            },
            {
              id_no: 6,
              name_type: 'LiqDisb Collector',
              path: '/admin/referential/accountability/liq-disb-collector',
              active: true
            },
            {
              id_no: 7,
              name_type: 'Office/Department',
              path: '/admin/referential/accountability/office-department',
              active: false
            },
            {
              id_no: 8,
              name_type: 'Cost Center',
              path: '/admin/referential/accountability/cost-center',
              active: true
            },
            {
              id_no: 9,
              name_type: 'Creditor/Payee',
              path: '/admin/referential/accountability/creditor-payee',
              active: true
            },
            {
              id_no: 10,
              name_type: 'Denominations',
              path: '/admin/referential/accountability/denominations',
              active: false
            },
            {
              id_no: 11,
              name_type: 'OR Template',
              path: '/admin/referential/accountability/or-template',
              active: true
            },
            {
              id_no: 12,
              name_type: 'Business Type',
              path: '/admin/referential/accountability/business-type',
              active: false
            }
          ]
        },
        {
          id: 6,
          name: 'Check Signatory',
          popup: null,
          popupRef: null,
          setPopup: null,
          path: '/admin/referential/check-signatory',
          subtabs: [],
          active: false
        },
        {
          id: 7,
          name: 'Collection Type',
          popup: null,
          popupRef: null,
          setPopup: null,
          path: '/admin/collection-type',
          subtabs: [],
          active: true
        }
      ]
    },
    {
      id: 3,
      type: 'Process',
      width: 450,
      open: processOpen,
      ref: processRef,
      setOpen: setProcessOpen,
      tabs: [
        {
          id: 1,
          name: 'Report of Collection and Deposits(RCD) Processing',
          popup: null,
          popupRef: null,
          setPopup: null,
          path: '/admin/process/rcd-processing',
          subtabs: [],
          active: true
        },
        {
          id: 2,
          name: 'Report of Collection by Liquidating Officer(RCLO) Processing',
          popup: null,
          popupRef: null,
          setPopup: null,
          path: '/admin/process/rclo-processing',
          subtabs: [],
          active: true
        },
        {
          id: 3,
          name: 'Report of Disbursement(RD) Processing',
          popup: null,
          popupRef: null,
          setPopup: null,
          path: '/admin/process/rd-processing',
          subtabs: [],
          active: false
        },
        {
          id: 4,
          name: 'Reversion / Augmentation',
          popup: null,
          popupRef: null,
          setPopup: null,
          path: '/admin/process/reversion-augmentation',
          subtabs: [],
          active: false
        },
        {
          id: 5,
          name: 'Recalculate Transaction',
          popup: null,
          popupRef: null,
          setPopup: null,
          path: '/admin/process/recalculate-transaction',
          subtabs: [],
          active: false
        },
        {
          id: 6,
          name: 'Cash Book Posting',
          popup: null,
          popupRef: null,
          setPopup: null,
          path: '/admin/process/cash-book-posting',
          subtabs: [],
          active: false
        },
        {
          id: 7,
          name: 'RPT-TIS Official Receipt Posting',
          popup: null,
          popupRef: null,
          setPopup: null,
          path: '/admin/process/rpt-tis-official-receipt-posting',
          subtabs: [],
          active: false
        },
        {
          id: 8,
          name: 'Utility',
          popup: utilityOpen,
          popupRef: utilityRef,
          setPopup: setUtilityOpen,
          path: null,
          subtabs: [
            {
              id_no: 1,
              name_type: 'Process Accountable Forms Grouping',
              path: '/admin/process/utility/process-accountable-forms-grouping',
              active: false
            },
            {
              id_no: 2,
              name_type: 'Update Accountable Form',
              path: '/admin/process/utility/update-accountable-form',
              active: false
            }
          ]
        }
      ]
    },
    {
      id: 4,
      type: 'Reports',
      width: 370,
      open: reportOpen,
      ref: reportRef,
      setOpen: setReportOpen,
      tabs: [
        {
          id: 1,
          name: 'Accountable Forms',
          popup: accountabilityFormsOpen,
          popupRef: accountabilityFormsRef,
          setPopup: setAccountabilityFormsOpen,
          path: null,
          subtabs: [
            {
              id_no: 1,
              name_type: 'Report of Accountability for Accountable Forms',
              path:
                '/admin/reports/accountable-forms/report-accountability-accountable-forms',
              active: true
            },
            {
              id_no: 2,
              name_type:
                'Consolidated Report of Accountability for Accountable Forms',
              path:
                '/admin/reports/accountable-forms/consolidated-report-accountability-accountable-forms',
              active: true
            },
            {
              id_no: 3,
              name_type:
                'Report of Accountability for Accountable Forms with Collection',
              path:
                '/admin/reports/accountable-forms/report-accountability-accountable-forms-collection',
              active: false
            },
            {
              id_no: 4,
              name_type: 'Report of Accountability for Accountable Forms (COA)',
              path:
                '/admin/reports/accountable-forms/report-accountability-accountable-forms-coa',
              active: false
            }
          ]
        },
        {
          id: 2,
          name: 'Collection',
          popup: repCollectionOpen,
          popupRef: repCollectionRef,
          setPopup: setRepCollectionOpen,
          path: null,
          subtabs: [
            {
              id_no: 1,
              name_type: 'Abstract of Collection',
              path: '/admin/reports/collection/abstract-collection',
              active: true
            },
            {
              id_no: 2,
              name_type: 'Abstract of Collection by Sub Group',
              path: '/admin/reports/collection/abstract-collection-sub-group',
              active: false
            },
            {
              id_no: 3,
              name_type: 'Daily Collection Report by Payee',
              path: '/admin/reports/collection/daily-collection-reportby-payee',
              active: false
            },
            {
              id_no: 4,
              name_type: 'Daily Collection Report by Account',
              path:
                '/admin/reports/collection/daily-collection-reportby-account',
              active: false
            },
            {
              id_no: 5,
              name_type: 'Daily Collection Report by Account per Collector',
              path:
                '/admin/reports/collection/daily-collection-reportby-accountper-collector',
              active: false
            },
            {
              id_no: 5,
              name_type: 'Daily Collection Report by Type',
              path: '/admin/reports/collection/daily-collection-reportby-type',
              active: false
            },
            {
              id_no: 6,
              name_type: 'Daily Collection Report by Group Account',
              path:
                '/admin/reports/collection/daily-collection-reportby-group-account',
              active: false
            },
            {
              id_no: 7,
              name_type: 'Daily Collection Report (Business)',
              path:
                '/admin/reports/collection/daily-collection-report-business',
              active: false
            },
            {
              id_no: 8,
              name_type: 'Daily Collection Report Summary by Account',
              path:
                '/admin/reports/collection/daily-collection-report-summaryby-account',
              active: false
            },
            {
              id_no: 9,
              name_type: 'Daily Collection Summary Report by Official Receipt',
              path:
                '/admin/reports/collection/daily-collection-summary-reportby-official-receipt',
              active: false
            },
            {
              id_no: 10,
              name_type: 'Daily Revenue Report by Account',
              path: '/admin/reports/collection/daily-revenue-report-account',
              active: false
            },
            {
              id_no: 11,
              name_type: 'Report of Revenue and Receipts(RRR)',
              path: '/admin/reports/collection/report-revenue-receipt',
              active: false
            },
            {
              id_no: 12,
              name_type: 'Revenue Subsidiary Ledger(RSL)',
              path: '/admin/reports/collection/revenue-subsidiary-ledger',
              active: false
            },
            {
              id_no: 13,
              name_type: 'Report of Collection and Deposit Balances',
              path:
                '/admin/reports/collection/report-collection-deposit-balances',
              active: false
            },
            {
              id_no: 14,
              name_type: 'Report of Collection and Deposits Liq Balances',
              path:
                '/admin/reports/collection/report-collection-deposits-liq-balances',
              active: false
            }
          ]
        },
        {
          id: 3,
          name: 'Report of Collection and Deposit(RCD)',
          popup: null,
          popupRef: null,
          setPopup: null,
          path: '/admin/reports/rcd',
          subtabs: [],
          active: true
        },
        {
          id: 4,
          name: 'Report of Collection by Liquidating Officer(RCLO)',
          popup: null,
          popupRef: null,
          setPopup: null,
          path: '/admin/reports/rclo',
          subtabs: [],
          active: true
        },
        {
          id: 5,
          name: 'Listings',
          popup: listingOpen,
          popupRef: listingRef,
          setPopup: setListingOpen,
          path: null,
          subtabs: [
            {
              id_no: 1,
              name_type: 'List of OR Sold',
              path: '/admin/reports/listing/list-or-sold',
              active: false
            },
            {
              id_no: 2,
              name_type: 'List of Cancelled ORs',
              path: '/admin/reports/listing/list-cancelled-ors',
              active: false
            },
            {
              id_no: 3,
              name_type: 'List of OR for RCD',
              path: '/admin/reports/listing/list-or-for-rcd',
              active: false
            },
            {
              id_no: 4,
              name_type: 'List of OR for RCLO',
              path: '/admin/reports/listing/list-or-for-rclo',
              active: false
            },
            {
              id_no: 5,
              name_type: 'List of ALOBS for the Day',
              path: '/admin/reports/listing/list-alobs-for-the-day',
              active: false
            },
            {
              id_no: 6,
              name_type: 'List of DV for PTO Approval',
              path: '/admin/reports/listing/list-dv-for-pto-approval',
              active: false
            },
            {
              id_no: 7,
              name_type: 'List of DV for releasing',
              path: '/admin/reports/listing/list-dv-for-releasing',
              active: false
            },
            {
              id_no: 8,
              name_type: 'List of Realeased DV',
              path: '/admin/reports/listing/list-released-dv',
              active: false
            },
            {
              id_no: 9,
              name_type: 'List of Cancelled DV',
              path: '/admin/reports/listing/list-cancelled-dv',
              active: false
            },
            {
              id_no: 10,
              name_type: "Creditor's Index Card",
              path: '/admin/reports/listing/creditor-index-card',
              active: false
            }
          ]
        },
        {
          id: 6,
          name: 'Disbursements',
          popup: repDisbursementOpen,
          popupRef: repDisbursementRef,
          setPopup: setRepDisbursementOpen,
          path: null,
          subtabs: [
            {
              id_no: 1,
              name_type: 'Advice of Allotment Report',
              path: '/admin/reports/disbursement/advice-allotment-report',
              active: false
            },
            {
              id_no: 2,
              name_type: 'Quarterly Allotment Balance Report',
              path:
                '/admin/reports/disbursement/quarterly-allotment-balance-report',
              active: false
            },
            {
              id_no: 3,
              name_type: 'Appropriation/Allotment Balance',
              path:
                '/admin/reports/disbursement/appropriation-allotment-balance',
              active: false
            },
            {
              id_no: 4,
              name_type: 'Statement of Expenditure by Sector',
              path:
                '/admin/reports/disbursement/statement-expenditure-by-sector',
              active: false
            },
            {
              id_no: 5,
              name_type: 'Statement of Expenditure by Function',
              path:
                '/admin/reports/disbursement/statement-expenditure-by-function',
              active: false
            },
            {
              id_no: 6,
              name_type:
                'Summary of Disbursements and Appropriations by Sector',
              path:
                '/admin/reports/disbursement/summary-disbursement-appropriations-by-sector',
              active: false
            },
            {
              id_no: 7,
              name_type: 'Record of Actual Disbursements',
              path: '/admin/reports/disbursement/record-actual-disbursements',
              active: false
            },
            {
              id_no: 8,
              name_type: 'Record of Appropriation Obligation and Expenditures',
              path:
                '/admin/reports/disbursement/record-appropriation-obligation-expenditures',
              active: false
            },
            {
              id_no: 9,
              name_type: 'Record of Expenditures',
              path: '/admin/reports/disbursement/record-expenditures',
              active: false
            },
            {
              id_no: 10,
              name_type: 'Accounts Payables',
              path: '/admin/reports/disbursement/accounts-payables',
              active: false
            },
            {
              id_no: 11,
              name_type: "Accountant's Advice",
              path: '/admin/reports/disbursement/accountants-advice',
              active: false
            },
            {
              id_no: 12,
              name_type: 'Check Register',
              path: '/admin/reports/disbursement/check-register',
              active: false
            },
            {
              id_no: 13,
              name_type: 'Cash Book',
              path: '/admin/reports/disbursement/cash-book',
              active: false
            }
          ]
        },
        {
          id: 7,
          name: 'RCLO Auto PDF',
          popup: null,
          popupRef: null,
          setPopup: null,
          path: '/admin/reports/rclo-auto-pdf',
          subtabs: [],
          active: false
        },
        {
          id: 7,
          name: 'List of Burial Permit Issued',
          popup: null,
          popupRef: null,
          setPopup: null,
          path: '/admin/reports/list-burial-permit-issued',
          subtabs: [],
          active: false
        }
      ]
    },
    {
      id: 5,
      type: 'Setup',
      width: 200,
      open: setupOpen,
      ref: setupRef,
      setOpen: setSetupOpen,
      tabs: [
        {
          id: 1,
          name: 'Cash Book Balance',
          popup: null,
          popupRef: null,
          setPopup: null,
          path: '/admin/setup/cash-book-balance',
          subtabs: [],
          active: false
        },
        {
          id: 2,
          name: 'System Security',
          popup: systemSecurityOpen,
          popupRef: systemSecurityRef,
          setPopup: setSystemSecurityOpen,
          path: null,
          subtabs: [
            {
              id_no: 1,
              name_type: 'Log-on as Different User',
              path: '/admin/setup/system-security/log-on-as-different-user',
              active: false
            },
            {
              id_no: 2,
              name_type: 'User Access',
              path: '/admin/setup/system-security/user-access',
              active: false
            },
            {
              id_no: 3,
              name_type: 'Lock',
              path: '/admin/setup/system-security/lock',
              active: false
            }
          ]
        },
        {
          id: 3,
          name: 'Signatories',
          popup: null,
          popupRef: null,
          setPopup: null,
          path: '/admin/setup/signatories',
          subtabs: [],
          active: false
        }
      ]
    },
    user.roles[0].name === 'admin'
      ? {
          id: 6,
          type: 'Admin',
          width: 200,
          open: setupOpen,
          ref: setupRef,
          setOpen: setSetupOpen,
          tabs: [
            {
              id: 1,
              name: 'Users',
              popup: null,
              popupRef: null,
              setPopup: null,
              path: '/admin/users',
              subtabs: [],
              active: true
            }
          ]
        }
      : {
          id: 6,
          type: 'Admin',
          width: 200,
          open: adminOpen,
          ref: adminRef,
          setOpen: setAdminOpen,
          tabs: []
        }
  ];

  const handleLogout = async () => {
    try {
      // handleClose();
      await dispatch(logout());
      history.push('/');
    } catch (error) {
      enqueueSnackbar('Unable to logout', {
        variant: 'error'
      });
    }
  };

  return (
    <AppBar className={clsx(classes.root, className)} {...rest}>
      <Toolbar className={classes.toolbar}>
        <Hidden lgUp>
          <IconButton
            className={classes.menuButton}
            color="inherit"
            onClick={onMobileNavOpen}
          >
            <SvgIcon fontSize="small">
              <MenuIcon />
            </SvgIcon>
          </IconButton>
        </Hidden>
        <Hidden mdDown>
          {navtab.length
            ? navtab.map(({ type, tabs, width, id, open, ref, setOpen }) => (
                <div ref={ref} key={id}>
                  <Button
                    onClick={() => {
                      setOpen(true);
                      setSelected1stID(type);
                    }}
                  >
                    <Typography variant="caption" style={{ color: '#fff' }}>
                      {type}
                    </Typography>
                  </Button>

                  {tabs.length > 0 ? (
                    <Menu
                      onClose={() => {
                        setOpen(false);
                        setSelected1stID('');
                      }}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left'
                      }}
                      keepMounted
                      PaperProps={{
                        style: {
                          width: width,
                          marginTop: 10
                          // margin: 0
                          // marginLeft: 8
                        }
                      }}
                      getContentAnchorEl={null}
                      anchorEl={ref.current}
                      open={open && selected1stID === type}
                    >
                      {tabs.map(
                        (
                          {
                            name,
                            path,
                            popup,
                            popupRef,
                            setPopup,
                            subtabs,
                            width2,
                            active
                          },
                          index2
                        ) => (
                          <div key={index2} ref={popupRef}>
                            <MenuItem
                              component={
                                path !== null
                                  ? active
                                    ? RouterLink
                                    : null
                                  : null
                              }
                              to={path !== null ? (active ? path : null) : null}
                              className="menuitemtopbar"
                              onClick={() => {
                                if (path === null) {
                                  setPopup(true);
                                  setSelected2ndID(name);
                                } else {
                                  setOpen(false);
                                  setSelected1stID('');
                                }
                                // console.log('name: ', name);
                              }}
                            >
                              <div
                                style={{
                                  width: 5,
                                  height: 5,
                                  borderRadius: 2.5,
                                  backgroundColor: active ? 'green' : '#e5e5e5',
                                  marginRight: 5,
                                  display: path === null ? 'none' : 'block'
                                }}
                              />
                              <div style={{ flexGrow: 1 }}>{name}</div>
                              {path === null && (
                                <div
                                  style={{
                                    display: 'flex',
                                    alignItems: 'flex-end',
                                    maxWidth: 16
                                  }}
                                >
                                  <ListItemIcon>
                                    <ArrowForwardIosIcon
                                      style={{ fontSize: 14 }}
                                    />
                                  </ListItemIcon>
                                </div>
                              )}
                            </MenuItem>
                            {tabs.length === index2 + 1 ? null : <Divider />}

                            {subtabs.length > 0 ? (
                              <Menu
                                onClose={() => {
                                  setPopup(false);
                                  setSelected2ndID('');
                                }}
                                anchorOrigin={{
                                  vertical: 'top',
                                  horizontal: 'right'
                                }}
                                keepMounted
                                PaperProps={{
                                  style: {
                                    borderRadius: 0,
                                    marginLeft: 1
                                  }
                                }}
                                getContentAnchorEl={null}
                                anchorEl={popupRef.current}
                                open={popup && selected2ndID === name}
                              >
                                {subtabs.map((data3, index3) => (
                                  <div key={index3}>
                                    <MenuItem
                                      component={
                                        data3.path !== null
                                          ? data3.active
                                            ? RouterLink
                                            : null
                                          : null
                                      }
                                      to={
                                        data3.path !== null
                                          ? data3.active
                                            ? data3.path
                                            : null
                                          : null
                                      }
                                      className="menuitemtopbar"
                                      onClick={() => {
                                        setOpen(false);
                                        setSelected2ndID('');
                                        // console.log('name: ', name);
                                      }}
                                    >
                                      <div
                                        style={{
                                          width: 5,
                                          height: 5,
                                          borderRadius: 2.5,
                                          backgroundColor: data3.active
                                            ? 'green'
                                            : '#e5e5e5',
                                          marginRight: 5,
                                          display:
                                            data3.path === null
                                              ? 'none'
                                              : 'block'
                                        }}
                                      />
                                      {data3.name_type}
                                    </MenuItem>
                                    {subtabs.length === index3 + 1 ? null : (
                                      <Divider />
                                    )}
                                  </div>
                                ))}
                              </Menu>
                            ) : null}
                          </div>
                        )
                      )}
                    </Menu>
                  ) : null}
                </div>
              ))
            : null}

          <Button onClick={handleLogout}>
            <Typography variant="caption" style={{ color: '#fff' }}>
              Exit
            </Typography>
          </Button>
        </Hidden>
        <Box ml={2} flexGrow={1} />
        <Hidden xsDown xsUp>
          <Search />
          <Contacts />
          <Notifications />
        </Hidden>
        <Box ml={2} id="stepTen">
          <Account />
        </Box>
      </Toolbar>
    </AppBar>
  );
}

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func
};

export default TopBar;
