// eslint-disable no-use-before-define
// eslint-disable react/prop-types
import React, { Fragment, useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Divider,
  Drawer,
  Hidden,
  Link,
  List,
  ListSubheader,
  Typography,
  Button,
  makeStyles,
  Collapse,
  ListItemIcon,
  ListItemText
  // ListItemButton
} from '@material-ui/core';
import {
  ArrowRight,
  Bookmark,
  Clipboard,
  Folder,
  LogOut,
  RefreshCw,
  // Settings,
  User
} from 'react-feather';
import Logo from 'src/components/Logo';
import { useSnackbar } from 'notistack';
import { logout } from 'src/actions/accountActions';

// import NavItem from './NavItem';

// function NavList({ items, ...rest }) {
//   const [expandedItem, setExpandedItem] = useState(null);

//   const handleToggle = item => {
//     // console.log('ITEM: ', item);
//     setExpandedItem(item);
//   };

//   return (
//     <List disablePadding>
//       {items.reduce(
//         (acc, item) =>
//           reduceChildRoutes({ acc, item, handleToggle, expandedItem, ...rest }),
//         []
//       )}
//     </List>
//   );
// }

// function reduceChildRoutes({
//   acc,
//   item,
//   depth = 0,
//   handleToggle,
//   expandedItem
// }) {
//   const key = item.title + depth;

//   acc.push(
//     <React.Fragment key={key}>
//       <NavItem
//         style={{
//           display: 'block'
//         }}
//         depth={depth}
//         href={item.href}
//         icon={item.icon}
//         title={item.title}
//         enabled={item.enabled}
//         onClick={() => {
//           handleToggle(item);
//         }}
//       />
//       {console.log(
//         'expandedItem !== null && expandedItem.titled === item.title: ',
//         expandedItem !== null && expandedItem.title === item.title
//       )}
//       <Collapse
//         in={expandedItem !== null && expandedItem.title === item.title}
//         timeout="auto"
//         unmountOnExit
//       >
//         qwe
//       </Collapse>
//     </React.Fragment>
//   );

//   return acc;
// }

const imageLocation = process.env.REACT_APP_IMAGE;

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)'
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64
  }
}));

function NavBar({ openMobile, onMobileClose }) {
  const classes = useStyles();
  const location = useLocation();
  const { user } = useSelector(state => state.account);
  const [selectedNav, setSelectedNav] = useState(null);
  const [selectedNav2, setSelectedNav2] = useState(null);
  const dispatch = useDispatch();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  // console.log('openMobile: ', openMobile);

  const navConfig = [
    //admin
    {
      subheader: '',
      owner: 'admin',
      items: [
        {
          title: 'Transaction',
          icon: Clipboard,
          enabled: true,
          items: [
            {
              id: 1,
              title: 'Collection',
              path: null,
              items: [
                {
                  id: 1,
                  title: 'Issuance of Official Receipt(OR)',
                  path:
                    '/admin/transaction/collection/issuance-official-receipt'
                },
                {
                  id: 2,
                  title: 'Business Permit(OR)',
                  path: '/admin/transaction/collection/business-permit'
                },
                {
                  id: 3,
                  title: 'Community Tax Certificate(CTC) - Ind',
                  path: '/admin/transaction/collection/ctc-ind'
                },
                {
                  id: 4,
                  title: 'Community Tax Certificate(CTC) - Corp',
                  path: '/admin/transaction/collection/ctc-corp'
                },
                {
                  id: 5,
                  title: 'Burial Permit and Fee Receipt',
                  path:
                    '/admin/transaction/collection/burial-permit-fee-receipt'
                }
              ]
            },
            {
              id: 2,
              title: 'Disbursement',
              path: null,
              items: [
                {
                  id: 1,
                  title: 'Disbursement Voucher(DV)',
                  path: '/admin/transaction/disbursement/disbursement-voucher'
                }
              ]
            }
          ]
        },
        {
          title: 'Referential',
          icon: Bookmark,
          enabled: true,
          items: [
            {
              id: 1,
              title: 'Fund',
              path: null,
              items: [
                {
                  id: 1,
                  title: 'Fund Group',
                  path: '/admin/referential/fund/fund-group'
                },
                {
                  id: 2,
                  title: 'Fund Code',
                  path: '/admin/referential/fund/fund-code'
                }
              ]
            },
            {
              id: 2,
              title: 'Allotment',
              path: null,
              items: [
                {
                  id: 1,
                  title: 'Allotment Code',
                  path: '/admin/referential/allotment/allotment-code'
                },
                {
                  id: 2,
                  title: 'Allotment Class',
                  path: '/admin/referential/allotment/allotment-class'
                }
              ]
            },
            {
              id: 3,
              title: 'FPP',
              path: null,
              items: [
                {
                  id: 1,
                  title: 'Function Code',
                  path: '/admin/referential/fpp/function-code'
                },
                {
                  id: 2,
                  title: 'Program Code',
                  path: '/admin/referential/fpp/program-code'
                },
                {
                  id: 3,
                  title: 'FPP Code',
                  path: '/admin/referential/fpp/fpp-code'
                }
              ]
            },
            {
              id: 4,
              title: 'Chart Of Accounts',
              path: null,
              items: [
                {
                  id: 1,
                  title: 'Account Code',
                  path: '/admin/referential/chart-accounts/account-code'
                },
                {
                  id: 2,
                  title: 'Object Code',
                  path: '/admin/referential/chart-accounts/object-code'
                }
              ]
            },
            {
              id: 6,
              title: 'Accountability',
              path: null,
              items: [
                {
                  id: 1,
                  title: 'Accountable Forms',
                  path: '/admin/referential/accountability/accountable-forms'
                },
                {
                  id: 2,
                  title: 'Check Booklets',
                  path: '/admin/referential/accountability/check-booklets'
                },
                {
                  id: 3,
                  title: 'Bank Depository',
                  path: '/admin/referential/accountability/bank-depository'
                },
                {
                  id: 4,
                  title: 'Form Code',
                  path: '/admin/referential/accountability/form-code'
                },
                {
                  id: 5,
                  title: 'Officer Type',
                  path: '/admin/referential/accountability/officer-type'
                },
                {
                  id: 6,
                  title: 'LiqDisb Collector',
                  path: '/admin/referential/accountability/liq-disb-collector'
                },
                {
                  id: 7,
                  title: 'Cost Center',
                  path: '/admin/referential/accountability/cost-center'
                },
                {
                  id: 8,
                  title: 'Creditor/Payee',
                  path: '/admin/referential/accountability/creditor-payee'
                },
                {
                  id: 9,
                  title: 'OR Template',
                  path: '/admin/referential/accountability/or-template'
                }
              ]
            },
            {
              id: 7,
              title: 'Collection Type',
              path: '/admin/collection-type'
            }
          ]
        },
        {
          title: 'Process',
          icon: RefreshCw,
          enabled: true,
          items: [
            {
              id: 1,
              title: 'Report of Collection and Deposits(RCD) Processing',
              path: '/admin/process/rcd-processing'
            },
            {
              id: 2,
              title:
                'Report of Collection by Liquidating Officer(RCLO) Processing',
              path: '/admin/process/rclo-processing'
            }
          ]
        },
        {
          title: 'Reports',
          icon: Folder,
          enabled: true,
          items: [
            {
              id: 1,
              title: 'Accountable Forms',
              path: null,
              items: [
                {
                  id: 1,
                  title: 'Report of Accountability for Accountable Forms',
                  path:
                    '/admin/reports/accountable-forms/report-accountability-accountable-forms'
                },
                {
                  id: 2,
                  title:
                    'Consolidated Report of Accountability for Accountable Forms',
                  path:
                    '/admin/reports/accountable-forms/consolidated-report-accountability-accountable-forms'
                }
              ]
            },
            {
              id: 2,
              title: 'Collection',
              path: null,
              items: [
                {
                  id: 1,
                  title: 'Abstract of Collection',
                  path: '/admin/reports/collection/abstract-collection'
                }
              ]
            },
            {
              id: 3,
              title: 'Report of Collection and Deposit(RCD)',
              path: '/admin/reports/rcd'
            },
            {
              id: 4,
              title: 'Report of Collection by Liquidating Officer(RCLO)',
              path: '/admin/reports/rclo'
            }
          ]
        },
        // {
        //   title: 'Setup',
        //   icon: Settings,
        //   enabled: true
        // },
        {
          title: 'Admin',
          icon: User,
          enabled: true,
          items: [
            {
              id: 1,
              title: 'Users',
              path: '/admin/users'
            }
          ]
        }
        // {
        //   title: 'Exit',
        //   icon: LogOut,
        //   enabled: true
        // }
      ]
    }
  ];

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line
  }, [location.pathname]);

  const handleLogout = async () => {
    try {
      // handleClose();
      await dispatch(logout());
      history.push('/');
    } catch (error) {
      enqueueSnackbar('Unable to logout', {
        variant: 'error'
      });
    }
  };

  const content = (
    <Box
      height="100%"
      display="flex"
      flexDirection="column"
      // style={{ border: '1px solid red' }}
    >
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Hidden lgUp>
          <Box p={2} display="flex" justifyContent="center">
            <RouterLink to="/">
              <Logo />
            </RouterLink>
          </Box>
        </Hidden>
        {/* {console.log('PIL')} */}
        <Box p={2}>
          <Box display="flex" justifyContent="center">
            <RouterLink
              to={
                user.roles[0].name.trim() === 'admin'
                  ? '/admin/account'
                  : '/patient/account'
              }
            >
              <Avatar
                alt={user.firstname}
                className={classes.avatar}
                src={`${imageLocation}avatar/${user.avatar}`}
              />
            </RouterLink>
          </Box>
          <Box mt={2} textAlign="center">
            <Link
              component={RouterLink}
              to={
                user.roles[0].name.trim() === 'admin'
                  ? '/admin/account'
                  : '/patient/account'
              }
              variant="h5"
              color="textPrimary"
              underline="none"
            >
              {`${user.firstname} ${user.lastname}`}
            </Link>
            <Typography variant="body2" color="textSecondary">
              {user.email}
            </Typography>
          </Box>
        </Box>
        <Divider />
        <Box p={2}>
          {navConfig
            .filter(x => user.roles[0].name.trim() === x.owner)
            .map((config, index) => (
              <List
                style={{
                  width: '100%'
                }}
                key={index}
                subheader={
                  <ListSubheader disableGutters disableSticky>
                    {config.subheader}
                  </ListSubheader>
                }
                component="nav"
              >
                {config.items.map((item, index2) => (
                  <Fragment key={index2}>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: 10,
                        padding: 2,
                        cursor: 'pointer'
                      }}
                      onClick={() => {
                        setSelectedNav(prevState =>
                          prevState === item.title ? null : item.title
                        );
                        setSelectedNav2(null);
                      }}
                    >
                      <ListItemIcon>{<item.icon />}</ListItemIcon>
                      <ListItemText primary={item.title} />
                    </div>
                    <Collapse
                      in={selectedNav !== null && selectedNav === item.title}
                      timeout="auto"
                      unmountOnExit
                    >
                      {item.items &&
                        item.items.length > 0 &&
                        item.items.map((data, key) => (
                          <Fragment key={key}>
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                gap: 10,
                                padding: 2,
                                cursor: 'pointer',
                                marginLeft: 22
                              }}
                              onClick={() => {
                                if (data.path !== null) {
                                  history.push(data.path);
                                  setSelectedNav2(null);
                                } else {
                                  setSelectedNav2(prevState =>
                                    prevState === data.title ? null : data.title
                                  );
                                }
                              }}
                            >
                              <ListItemText primary={data.title} />
                              <ListItemIcon>
                                {data.path !== null ? (
                                  <ArrowRight />
                                ) : (
                                  <Folder />
                                )}
                              </ListItemIcon>
                            </div>

                            <Collapse
                              in={
                                selectedNav2 !== null &&
                                selectedNav2 === data.title
                              }
                              timeout="auto"
                              unmountOnExit
                            >
                              {data.items &&
                                data.items.length > 0 &&
                                data.items.map((data2, key2) => (
                                  <Fragment key={key2}>
                                    <div
                                      style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        gap: 10,
                                        padding: 2,
                                        cursor: 'pointer',
                                        marginLeft: 32
                                      }}
                                      onClick={() => {
                                        history.push(data2.path);
                                      }}
                                    >
                                      <ListItemText primary={data2.title} />
                                      <ListItemIcon>
                                        <ArrowRight />
                                      </ListItemIcon>
                                    </div>
                                  </Fragment>
                                ))}
                            </Collapse>
                          </Fragment>
                        ))}
                    </Collapse>
                  </Fragment>
                ))}
              </List>
            ))}
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              gap: 10,
              padding: 2,
              cursor: 'pointer',
              marginTop: 20
            }}
            onClick={handleLogout}
          >
            <ListItemIcon>
              <LogOut />
            </ListItemIcon>
            <ListItemText primary={'Exit'} />
          </div>
        </Box>
        <Divider />
        <Hidden xsUp xsDown>
          <Box p={1}>
            <Box p={2} borderRadius="borderRadius" bgcolor="background.dark">
              <Typography variant="h6" color="textSecondary" align="center">
                To register your property? Click{' '}
              </Typography>

              <Box mt={1}>
                <Button
                  size="small"
                  variant="contained"
                  color="primary"
                  component={RouterLink}
                  to="/app/property/register"
                  fullWidth
                >
                  {' '}
                  Register{' '}
                </Button>
              </Box>
            </Box>
          </Box>
        </Hidden>
      </PerfectScrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      {/* <Hidden mdDown> */}
      {/* <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        > */}
      {/* {content} */}
      {/* </Drawer> */}
      {/* </Hidden> */}
    </>
  );
}

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default NavBar;
